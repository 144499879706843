:root {
  --primary-color: #ebe5d9;
  --secondary-color: #f5f5f5;
  --accent-bg: #140e25;
  --accent-hover-bg: #352563;
  --cc-grey: #8d8982;
}

.--font-FuturaBookBT-16-700 {
  font-family: FuturaBookBT;
  font-size: 16px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
}

.--object-fit-cover-center {
  object-fit: cover !important;
  object-position: center center !important;
}

.--text-center-line-through-lr {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid black;
  text-align: center;
  position: relative;
}
.--text-center-line-through-lr > * {
  background: #ebe5d8 !important;
  font-family: Trap;
  font-size: 16.38px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.835px;
  letter-spacing: -0.737px;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 40%) !important;
  padding: 0 8px 0 8px;
}

.--sticky-top {
  position: sticky !important;
  top: 0 !important;
}

.--transform-top-55 {
  transform: translateY(55%) !important;
}

.--hover-bg-transparent {
  background: transparent !important;
}
.--hover-bg-transparent:hover {
  background: transparent !important;
}

.--hover-bg-grey {
  background: transparent !important;
}
.--hover-bg-grey:hover {
  background: #e9ecef !important;
}

.--position-relative {
  position: relative !important;
}

.--variant-dark {
  background-color: #140e25 !important;
  color: #ffffff !important;
  border: solid 2px #140e25 !important;
}
.--variant-dark:hover {
  background-color: #ffffff !important;
  color: #140e25 !important;
  border: solid 2px #140e25 !important;
}

.--variant-light {
  background-color: #ffffff !important;
  color: #140e25 !important;
  border: solid 2px #140e25 !important;
}

.--variant-light:hover {
  background-color: #140e25 !important;
  color: #ffffff !important;
  border: solid 2px #140e25 !important;
}

.--position-top-right {
  position: absolute;
  top: 30px;
  right: 20px;
}

.--bg-orange-dark {
  background-color: rgba(209, 191, 150, 0.7) !important;
}

.--width-inherit {
  width: inherit !important;
}

.--height-inherit {
  height: inherit !important;
}

.--height-full-body {
  min-height: calc(100vh - 160px);
}

.--border-radius-circle {
  border-radius: 40px !important;
}

.--object-img-cover {
  object-fit: cover !important;
}

.--semi-transparent {
  background-color: rgba(235, 229, 216, 0.5) !important;
}

.--border-right-1px-solid {
  border-right: 1px solid #6c757d;
}

.--text-underline {
  text-decoration: underline;
  text-underline-offset: 10px;
}

.--border-3px-dashed {
  border: 3px dashed !important;
}

.--border-color-grey {
  border-color: #808080 !important;
}
.--border-color-grey:hover {
  border: none !important;
  height: 42px;
}

.--cursor-pointer {
  cursor: pointer !important;
}

.--bg-no-repeat {
  background-repeat: no-repeat !important;
}

.--form-properties {
  font-weight: bold;
  background: #d9d9d9;
  border: 0;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 6px 12px;
}

html,
body {
  background-color: var(--primary-color) !important;
  /* font-family: "Futura Book font" !important; */
  /* font-family: "FuturaMediumBT" !important; */
  font-family: "FuturaBookBT" !important;
}

.container {
  max-width: 1440px !important;
}

.font-42 {
  font-size: 42px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-32 {
  font-size: 32px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.cursor-none {
  cursor: auto !important;
}

.w-300px {
  width: 300px;
}

.w-200px {
  width: 200px;
}

.w-150px {
  width: 150px;
}

.w-100px {
  width: 100px;
}

.maxWidth-100px {
  max-width: 100px;
}

.maxWidth-200px {
  max-width: 200px;
}

.minWidth-100px {
  min-width: 100px;
}

.font-family-futurabookbt {
  font-family: "FuturaBookBT" !important;
}

.font-family-futuramdbt {
  font-family: "futura medium bt" !important;
}

.font-family-futuram {
  font-family: "FUTURAM" !important;
}

.font-family-futuramediumbt {
  font-family: "FuturaMediumBT" !important;
}

.font-family-futuraxblkbt {
  font-family: "Futura XBlk BT" !important;
}

.text-48B7E7 {
  color: #48b7e7;
}

.text-cc-grey {
  color: var(--cc-grey);
}

.bg-f5f5f5 {
  background: #f5f5f5;
}

.bg-d9d9d9 {
  background: #d9d9dd;
}

.bg-orange {
  background: orangered;
}

.bg-yellow {
  background: yellow;
}

.bg-light-blue {
  background: skyblue;
}

.bg-blue {
  background: var(--accent-bg) !important;
  transition: 0.2s ease-in-out;
}

.blue-text {
  color: var(--accent-bg) !important;
}

.bg-blue:hover {
  color: var(--accent-bg) !important;
  background-color: transparent !important;
}

.bg-white:hover {
  background-color: var(--accent-bg) !important;
  color: #fff !important;
}

.bg-transparent:hover {
  background-color: var(--accent-bg) !important;
  color: #fff !important;
}

.bg-transparent.social-login-btn:hover {
  background-color: unset !important;
  color: unset !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-accent {
  border-color: var(--accent-bg) !important;
}

.border-dashed {
  border-style: dashed;
}

.line-height-normal {
  line-height: normal;
}

.form-group.has-search input::placeholder,
.form-group.has-search input {
  color: #000 !important;
  font-weight: 700;
  font-size: 14px;
}

.form-group.has-search {
  position: relative;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  height: 40px;
  line-height: 40px;
  left: 10px;
}

button#search-btn {
  padding: 5px !important;
  border: 0;
  background: transparent;
  z-index: 99;
}

.desktop-main-menu > a:not(:last-child) {
  margin-right: 15px;
}

.avatar-img {
  max-width: 50px;
}

.job-card-div,
.job-details-card {
  border-radius: 20px !important;
  overflow: hidden;
}

.job-card-company {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.job-details-card {
  min-height: calc(100vh - 120px);
}

.job-details-container {
  line-height: 24px;
}

.job-card-div img {
  width: 75px;
  border: 1px solid #000;
  height: 75px;
  object-fit: cover;
  border-radius: 50% !important;
}

.job-card-div .card-body {
  padding: 0px 10px;
}

.job-card-div p.card-title {
  letter-spacing: 0.5px;
}

.job-card-div p.card-text {
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
}

.job-card-bookmark {
  top: 20px;
  right: 20px;
  z-index: 9;
}

.jd-card-img-overlay {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
}

.jd-card-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 300px;
}

.jd-card-banner,
.jd-banner-div {
  position: relative;
}

.jd-card-banner:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.25;
}

.ftr-links > a {
  padding: 0px 5px;
  color: #000;
}

.footer-container {
  /* max-width: 960px !important; */
  width: 100vw;
}

.ftr-social-links a:not(:last-child) {
  margin-right: 10px !important;
}

.ftr-social-links a img {
  width: 100%;
  max-width: 20px;
}

.login-layout-bg {
  background-size: cover;
}

.ls-form input {
  outline: none !important;
  background: transparent !important;
  margin-bottom: 0em;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-radius: 0 !important;
  border-color: var(--cc-grey);
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 16px;
  box-shadow: none !important;
  font-weight: bold;
}
.ls-form input:active {
  background: transparent !important;
}

.social-login-container > div {
  padding: 0;
  width: auto;
}

.mobile-sidebar-menu {
  width: 60% !important;
}

.mobile-sidebar-menu a,
.mobile-sidebar-menu button {
  background: transparent !important;
}

.mobile-sidebar-menu button {
  width: 100%;
}

.job-details-sidebar {
  margin-top: 90px;
  width: 100% !important;
}

.close-job-details-offcanvas {
  background: none !important;
  width: auto !important;
  text-decoration: underline;
  font-weight: 700;
  opacity: 1 !important;
}

.social-login-btn img {
  width: 40px;
}

.login-layout-bg {
  background: url("../public/assets/images/placeholders/Blue\ Gradient.svg")
    no-repeat center center;
  background-size: cover;
}

.role-card {
  background: var(--primary-color);
  box-shadow: 0px 0px 10px #afafaf;
}

.bookmark-top-right {
  width: auto !important;
  position: absolute;
  top: 15px;
  right: 15px;
}

.role-card * {
  background-color: inherit !important;
  /* font-family: FUTURAM; */
}

.job-details-container * {
  color: rgb(49, 50, 52) !important;
}

.btns-rows-mobile > button {
  width: 50%;
}

.btns-rows-mobile {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.jdm-header-div img.card-img-left {
  width: 75px;
  border: 1px solid #000;
  height: auto;
  border-radius: 50% !important;
}

.container-card-img-left {
  width: 75px !important;
  max-width: 75px !important;
  height: 75px;
  position: relative !important;
  box-sizing: content-box;
  padding: 0 !important;
}

.jdm-header-details {
  width: calc(100% - 75px);
}

button.bookmark-icon-mobile {
  position: absolute;
  top: 0px;
  right: 10px;
}

.apply-now-modal .modal-content,
.share-modal .modal-content {
  background-size: cover !important;
  background: url("../public/assets/images/placeholders/Green\ Gradient.svg")
    no-repeat center center;
}

.reset-password-modal {
  background-image: none;
}

.apply-now-modal .btn-close,
.share-modal .btn-close {
  filter: invert(1);
}

.apply-now-modal .tab-content .share-modal-inner-content,
.share-modal .tab-content .share-modal-inner-content {
  min-height: 80px;
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.apply-now-modal .nav-tabs .nav-link.active,
.share-modal .nav-tabs .nav-link.active {
  border-bottom: 3px solid #fff !important;
}

.apply-now-modal .nav-tabs .nav-link,
.share-modal .nav-tabs .nav-link {
  background: transparent !important;
  border: 0 !important;
  color: #fff !important;
  font-weight: bold;
}

.apply-now-modal .tab-content .share-modal-inner-content .form-group,
.share-modal .tab-content .share-modal-inner-content .form-group {
  display: flex;
  align-items: center;
  flex-flow: row;
  width: 100% !important;
}

.apply-now-modal .tab-content .share-modal-inner-content .form-group button,
.share-modal .tab-content .share-modal-inner-content .form-group button {
  height: 42px;
}

.apply-now-modal .tab-content .share-modal-inner-content .form-group input,
.share-modal .tab-content .share-modal-inner-content .form-group input {
  width: 90% !important;
  background: transparent;
  border: 1px solid #ffffff80 !important;
  color: #ffffff;
  border-radius: 6px;
  height: 40px;
  padding: 0 5px;
}

.apply-now-modal .modal-dialog {
  max-width: 60%;
}

div#profile-sidebar {
  background: var(--primary-color);
  margin-top: -10em;
  border-radius: 25px;
  box-shadow: 0px 0px 5px 3px #00000040;
  padding: 3em 2em;
  text-align: center;
}

.projects-container {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.projects-container img {
  height: 180px;
  object-fit: cover;
  object-position: center center;
}

.job-card-div {
  display: flex !important;
}

.job-card-div.card {
  cursor: pointer;
}

.jobs-container {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

.employer-job-card-div .card-body {
  padding-right: 20px;
}

.employer-details {
  padding: 3em 2em;
}

.cc-carousel .carousel-indicators {
  bottom: -50px;
}

.cc-carousel .carousel-indicators button.active {
  background-color: #151541 !important;
}

.cc-carousel .carousel-indicators button {
  border-top: unset !important;
  border-bottom: unset !important;
  border: 1px solid #000 !important;
  border-radius: 50% !important;
  background-color: #e2e2e2 !important;
  opacity: 1;
  height: 12px !important;
  width: 12px !important;
}

.cc-carousel .carousel-control-next,
.cc-carousel .carousel-control-prev {
  background: rgba(255, 255, 255, 0.25);
  width: 50px;
}

.cc-carousel .carousel-item img {
  max-height: 500px;
}

@media only screen and (max-width: 575px) {
  .ftr-social-links.ftr-right-menu a,
  .ftr-links.ftr-left-menu a {
    margin: 0 10px;
  }

  .ftr-social-links.ftr-right-menu,
  .ftr-links.ftr-left-menu {
    justify-content: center !important;
    margin-bottom: 1em;
  }

  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 991px) {
  .home-listing-container {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 991px) {
  .confirm-delete-modal .modal-dialog,
  .logout-modal .modal-dialog {
    max-width: 50%;
  }
}

a:hover {
  color: inherit !important;
}

#loaderBG {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1233;
  background: rgba(235, 229, 217, 0.5);
}

.loader {
  display: flex;
  margin: 0 auto;
}

.edit-profile-tabs {
  border: 0;
}

.edit-profile-tabs .nav-item .nav-link {
  font-weight: bold;
  color: #000 !important;
  border: 0;
  padding: 0;
  margin-right: 50px;
  margin-bottom: 50px;
}

.edit-profile-tabs .nav-item.show .nav-link,
.edit-profile-tabs .nav-link.active {
  background: transparent !important;
  border-bottom: 4px solid var(--accent-bg) !important;
}

#edit-profile-form label {
  font-weight: bold;
}

#edit-profile-form input {
  font-weight: bold;
  background: #ffffff;
  border: 0;
  border-radius: 0;
  margin-bottom: 30px;
}

.resumes-list {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 3em;
}

.apply-resumes-list {
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 3em;
}

.apply-resumes-list > div:nth-child(n + 4) {
  display: none;
}

.apply-resumes-list .resume-block {
  height: 250px;
  transition: 0.25s ease-in-out;
}

.apply-resumes-list .resume-block.active-resume {
  border-width: 3px !important;
  background: var(--secondary-color) !important;
}

.resume-block {
  word-break: break-word !important;
  height: 150px;
  cursor: pointer;
}

.resume-upload-block {
  border: 1px dashed #000;
}

.search-filter-btn {
  background: transparent !important;
}

.hidden {
  display: none;
}

.filters-main {
  z-index: 99;
  width: calc(100% - 40px);
  left: 20px;
  padding: 1em;
  background: #fff;
  box-shadow: 0px 0px 5px 3px #00000040;
}

.filters-main .accordion-item {
  border: 0 !important;
  margin-bottom: 1em;
}

.filters-main > div > .accordion-item:last-child {
  margin-bottom: 0 !important;
}

.filters-main button.accordion-button {
  padding: 0 !important;
  font-size: 16px;
  font-weight: 700;
  bottom: 0 !important;
  background: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
}

.filters-main button.accordion-button:after {
  margin-left: 10px;
  background-image: var(--bs-accordion-btn-icon) !important;
}

.filters-main .accordion-header {
  width: max-content;
}

.filters-main .accordion-body {
  padding: 10px 0 !important;
}

.filters-main .accordion-body label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

.filters-main .accordion-body > label:last-child {
  margin-bottom: 0 !important;
}

.filters-main .accordion-body label input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.desktop-main-menu > button {
  background-color: transparent !important;
}

.confirm-delete-modal .modal-dialog .modal-content,
.logout-modal .modal-dialog .modal-content {
  background: url("../public/assets/images/placeholders/Blue\ Gradient.svg")
    no-repeat center center;
  background-size: cover;
  border-radius: 0 !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.edit-profile-main .logout-btn {
  background: red !important;
  color: var(--cc-grey) !important;
  font-size: 16px !important;
}

.hdr-search-bar {
  background-color: transparent !important;
}

.search-filter-btn::after {
  display: none !important;
}

.filters-main.dropdown-menu {
  min-width: 300px;
  transform: translate(-250px, 35px) !important;
}

button#dropdown-autoclose-outside {
  z-index: 9;
}

.job-details-card::-webkit-scrollbar {
  display: none;
}

.job-details-card {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.select-user-profile-phone-prefix {
  height: 36px;
  width: 60px;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: #ebe5d8;
}
.select-user-profile-phone-prefix:hover {
  background: #ebe5d8 !important;
  color: #000000 !important;
}

.container-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 82px - 56px);
}

.ul-responsibilities > ul {
  list-style-type: disc;
  padding-left: 0;
}

.table-list {
}

.table-list > tbody,
.table-list > tbody > tr {
  min-width: 100%;
}

.table-list tr th {
  min-width: 50%;
}

.table-list tr th:nth-child(2) {
  padding-left: 30px !important;
}

.modal-content {
  max-width: 900px !important;
}

.employer-banner {
  width: 100%;
  height: 286px;
  object-fit: cover;
  object-position: center center;
}

#header-profile-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

#container-headerbar {
  position: sticky;
  margin-top: 0px;
  top: 0;
  z-index: 10;
}

[disabled] {
  cursor: not-allowed !important;
  z-index: 999 !important;
  position: relative !important;
}

@media (max-width: 767.99px) {
  .network-container .network-div {
    max-width: 150px;
  }
  .network-container .network-div > div:first-child {
    display: flex;
    justify-content: center;
  }

  .network-container .network-div > div:first-child img {
    margin: 0 !important;
  }

  .network-container .network-div-content {
    padding: 20px 10px;
    display: flex !important;
    flex-direction: column;
    text-align: center;
  }
}

.btn-tooltip {
  font-size: 16px !important;
  position: absolute;
  top: -10px;
  right: -35px;
}

.btn-tooltip:hover,
.btn-tooltip:focus,
.btn-tooltip:active {
  background: inherit !important;
  color: inherit !important;
}

.brand .col-2 {
  width: auto;
  border: none !important;
}

.brand-mobile-header {
  /* color: #000; */
  /* font-family: Futura Md BT; */
  /* font-size: 14.33px; */
  /* font-style: normal; */
  /* font-weight: 400; */
  /* line-height: normal; */
}

.brand-mobile-text {
  color: #000;
  font-family: Futura Md BT;
  font-size: 14.33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desktop-main-menu .dropdown-toggle:hover,
.desktop-main-menu .dropdown-toggle::after {
  background: none !important;
  content: none !important;
}

.mt-40 {
  margin-top: 40px !important;
}
@font-face {
	font-family: "futur";
	src: local("futur"),
		url("./fonts/Futura/futur.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Bold font";
	src: local("Futura Bold font"),
		url("./fonts/Futura/Futura Bold font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Bold Italic font";
	src: local("Futura Bold Italic font"),
		url("./fonts/Futura/Futura Bold Italic font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Book font";
	src: local("Futura Book font"),
		url("./fonts/Futura/Futura Book font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Book Italic font";
	src: local("Futura Book Italic font"),
		url("./fonts/Futura/Futura Book Italic font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Extra Black font";
	src: local("Futura Extra Black font"),
		url("./fonts/Futura/Futura Extra Black font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Heavy font";
	src: local("Futura Heavy font"),
		url("./fonts/Futura/Futura Heavy font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Heavy Italic font";
	src: local("Futura Heavy Italic font"),
		url("./fonts/Futura/Futura Heavy Italic font.ttf") format("truetype");
}

@font-face {
	font-family: "futura light bt";
	src: local("futura light bt"),
		url("./fonts/Futura/futura light bt.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Light font";
	src: local("Futura Light font"),
		url("./fonts/Futura/Futura Light font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Light Italic font";
	src: local("Futura Light Italic font"),
		url("./fonts/Futura/Futura Light Italic font.ttf") format("truetype");
}

@font-face {
	font-family: "futura medium bt";
	src: local("futura medium bt"),
		url("./fonts/Futura/futura medium bt.ttf") format("truetype");
}

@font-face {
	font-family: "futura medium condensed bt";
	src: local("futura medium condensed bt"),
		url("./fonts/Futura/futura medium condensed bt.ttf") format("truetype");
}

@font-face {
	font-family: "Futura Medium Italic font";
	src: local("Futura Medium Italic font"),
		url("./fonts/Futura/Futura Medium Italic font.ttf") format("truetype");
}

@font-face {
	font-family: "Futura XBlk BT";
	src: local("Futura XBlk BT"),
		url("./fonts/Futura/Futura XBlk BT.ttf") format("truetype");
}

@font-face {
	font-family: "Futura-CondensedLight";
	src: local("Futura-CondensedLight"),
		url("./fonts/Futura/Futura-CondensedLight.otf") format("opentype");
}

@font-face {
	font-family: "FutuBk";
	src: local("FutuBk"),
		url("./fonts/Futura/FutuBk.ttf") format("truetype");
}

@font-face {
	font-family: "FuturaBookBT";
	src: local("FuturaBookBT"),
		url("./fonts/Futura/FuturaBookBT.ttf") format("truetype");
}

@font-face {
	font-family: "FuturaMediumBT";
	src: local("FuturaMediumBT"),
		url("./fonts/Futura/FuturaMediumBT.ttf") format("truetype");
}

@font-face {
	font-family: "FUTURAM";
	src: local("FUTURAM"),
		url("./fonts/Futura/FUTURAM.TTF") format("truetype");
}
.error {
  font-family: FuturaBookBT;
  letter-spacing: 1.9px;
}

#profileImage {
  width: 90%;
  max-width: 232.58px;
  height: auto;
}

.container-cover-image {
  height: inherit !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;

  & #coverImage {
    width: 100%;
    height: auto;
    max-height: 232.58px;
    object-fit: cover;
    object-position: center;
  }

  & #header-image-label {
    background-size: cover !important;
    background-position: center center !important;
  }
}

.container-profile-image {
  height: 430px;
}
.container-cover-image {
  height: 430px;
}

@media (max-width: 768.99px) {
  .container-profile-image {
    border-bottom: 1px solid #6c757d;
    border-right: none;
    padding-bottom: 30px;
  }
  .container-cover-image {
    padding-top: 30px;
  }
}

.card {
  & .card-body-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  & .card-img {
    object-fit: contain;
  }

  & .card-body-project {
    display: flex;
    flex-direction: column;
    height: 120px;
    // background-color: rgba(0, 0, 0, 0.03);

    & .card-title {
      font-weight: 700;
    }
  }
}

.glassmorphism-card {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s ease;
}

.glassmorphism-card:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

#profile-banner {
  height: 286px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.container-project {
  position: relative;

  & .project {
    & hr {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      border: 1px solid #000000;
      transform: rotate(180deg);
    }

    & .project_main__back {
      font-family: "Futura Md BT";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    & .project_main__title {
      font-family: "Futura Hv BT";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 48px;
      color: #000000;
    }

    & .project_main__description {
      font-family: "Futura Bk BT";
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.8);
    }

    & .project_profile__avatar {
      padding-top: 24px;

      & img {
        min-width: 80px;
        width: 80px;
        min-height: 80px;
        height: 80px;
        object-fit: cover !important;
        object-position: bottom bottom !important;
        border-radius: 50%;
      }
    }

    & .project_profile__name {
      font-family: "Futura Hv BT";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #000000;
      padding-bottom: 5px;
    }

    & .project_profile__bio {
      padding-top: 5px;
      font-family: "Futura Bk BT";
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.8);
    }

    & .project_content_description {
      margin: 0 0 50px 42px;
      font-family: "Futura Bk BT";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  & .project-content {
    & .container-caption {
      padding: 0;
      margin: 0;
      font-family: "Futura Bk BT";
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.4);
    }
    margin-bottom: 40px;
  }

  & .w3-display-container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & .w3-display-left {
      position: absolute;
      top: 50%;
      left: 0%;
      transform: translate(0%, -50%);
      -ms-transform: translate(-0%, -50%);
      z-index: 2;
      padding: 16px;
      height: 100%;
    }
  }

  & .w3-section,
  & .w3-margin {
    margin: 16px !important;
  }

  & .w3-margin-top {
    margin-top: 16px !important;
  }

  & .project-section-profile {
    & > div {
      display: flex;
      flex-wrap: nowrap;
    }

    & .project-section-profile-bio {
      flex-grow: 1;
    }

    @media (min-width: 992px) {
      max-width: 360px;
      flex-wrap: wrap;
    }
  }

  @media (min-width: 768px) {
    width: 75%;
  }
}

.project-card {
  & img {
    object-fit: cover !important;
    object-position: center !important;
  }

  & .card-project-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    position: relative;

    &::after {
      content: "...";
      position: absolute;
      bottom: 0;
      right: 0;
      padding-left: 0.5em;
    }
  }
}

.project-edit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ebe3d8;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.7) !important;

  &:hover {
    color: #000000 !important;
  }
}

#profileImage {
  height: 245px;
  object-fit: contain;
  object-position: center center;
}

#coverImage {
  height: 245px;
  object-fit: contain;
  object-position: center center;
}

#profile-banner-label {
  height: 350px;
  position: relative;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#profile-image-label {
  height: 80px !important;
  width: 80px !important;
  border-radius: 50% !important;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#portfolio-image {
  height: 100% !important;
  width: auto !important;
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#profile-portfolio-banner {
  width: 100%;
  height: 350px;
  position: relative;
  object-fit: cover;
  object-position: center center;
  z-index: 1;
}

.btn-portfolio-delete {
  background-color: transparent;
  color: #dc3545;
  font-family: "Futura Md BT";
  font-size: 14px;
}

.btn-portfolio-delete:hover {
  background-color: #dc3545;
  color: #ffffff;
  font-family: "Futura Md BT";
}

#gallery-image-src {
  position: absolute;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
}

.portfolio-carousel-item {
  height: auto;
  width: 100%;
}

.btn-add-media {
  width: 42px;
  height: 42px;
}

.confirmation-modal {
  font-family: FuturaBookBT, sans-serif;

  & .modal-title {
    font-size: 22px !important;
    font-weight: 600;
  }
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 26px;
  overflow: hidden;

  .fill {
    height: 100%;
    border-radius: 10px;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 900;
    color: #000000;
    pointer-events: none;
  }
}

.progress-bar-sticky {
  position: sticky;
  margin-top: 0px;
  margin-bottom: 30px;
  top: 10px;
  z-index: 999;
}
